export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [];

export const dictionary = {
		"/": [18],
		"/aml-policy": [19,[2]],
		"/auth/otp-email": [20],
		"/auth/telegram": [21],
		"/casino": [22,[3]],
		"/casino/[provider]": [23,[3,4]],
		"/casino/[provider]/[game]": [24,[3,4,5]],
		"/crypto-games": [25,[6]],
		"/fairness": [26,[7]],
		"/faq": [27,[8]],
		"/live-casino": [28,[9]],
		"/privacy-policy": [29,[10]],
		"/promotions": [30,[11]],
		"/promotions/[promotion]": [31,[11,12]],
		"/responsible-gaming": [32,[13]],
		"/restricted-jurisdictions": [33,[14]],
		"/roadmap": [34,[15]],
		"/sportsbook": [35,[16]],
		"/terms-and-conditions": [36,[17]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';